

export const data = [
    {
        title:"全国",
        content:"全国朋友可见",
        active:true,
        type:0
    },{
        title:"全省",
        content:"全省朋友可见",
        active:false,
        type:1
    },{
        title:"本校",
        content:"本校朋友可见",
        active:false,
        type:2
    },{
        title:"好友",
        content:"仅好友可见",
        active:false,
        type:3
    },{
        title:"仅自己",
        content:"仅自己可见",
        active:false,
        type:4
    },
]
